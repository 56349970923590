import { Icon } from "@combateafraude/react";
import StepIcon from "@components/StepIcon";
import { MenuOptionsDefault, SuspenseMenu } from "@components/SuspenseMenu";
import { useFlowContext } from "@contexts/Flow";
import { useOnboardingOptionsContext } from "@contexts/OnboardingOptions";
import { useOnboardingStepsContext } from "@contexts/OnboardingSteps";
import { useSidebarContext } from "@contexts/Sidebar";
import { useSidebarEditorContext } from "@contexts/SidebarEditor";
import useFloatingSidebar from "@hooks/useFloatingSidebar";
import Onboarding from "@package/steps";
import {
  doNotAllowDuplicateDoneStep,
  doNotAllowRemoveDoneStep,
  stepToElement,
  updateElementStepData,
} from "@utils/flow";
import { getRandomNumber } from "@utils/index";
import { parseStep } from "@utils/onboarding";
import { cleanStep, indexStep } from "@utils/onboarding";
import { message } from "antd";
import classNames from "classnames";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Handle } from "react-flow-renderer";
import { removeElements, useUpdateNodeInternals } from "react-flow-renderer";
import { useTranslation } from "react-i18next";

const { steps } = Onboarding;

const I18N_BASE_PATH = "src.components.flowEditor.defaultStepNode";

/** TODO: Remove flowChoice options and move to a new type of node */
const DefaultStepNode = ({ id, data, type, selected, sourcePosition, targetPosition }) => {
  const { t } = useTranslation();
  const { unselectAllElements } = useSidebarEditorContext();
  const { setActivePane } = useSidebarContext();
  const updateNodeInternals = useUpdateNodeInternals();
  const { step } = data;

  const { isConnecting, getFlowColor, setElements, elements, reactFlowRef, reactFlowInstance } = useFlowContext();

  const [isFlowChoice, setIsFlowChoice] = useState(false);
  const [isSmartChoice, setIsSmartChoice] = useState(false);
  const [isDocumentReuse, setIsDocumentReuse] = useState(false);
  const [isDone, setDone] = useState(false);
  const [flowOptions, setFlowOptions] = useState();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [smartOptions, setSmartOptions] = useState([]);
  const [flowOptionsChanged, setFlowOptionsChanged] = useState(false);
  const [showSidebar, hideSidebar] = useFloatingSidebar({ onHide: () => unselectAllElements() });
  const { onboardingSteps } = useOnboardingStepsContext();

  const { isTemplateDefault, onboardingOptions } = useOnboardingOptionsContext();

  useEffect(() => {
    if (id.includes("SMART_CHOICE")) {
      const newOptions = onboardingOptions?.documentTypes?.map((documentType) => ({
        flow: documentType?.type,
        text: documentType?.type,
      }));

      onboardingSteps?.forEach((step) => {
        if (parseStep(step.name)[0] === steps.SMART_CHOICE.name) {
          const isDifferent =
            !step.options ||
            step.options.length !== newOptions.length ||
            step.options.some((option, index) => option.flow !== newOptions[index].flow);

          if (isDifferent) {
            step.ruleType = "DOCUMENT_TYPE";
            step.options = newOptions;
          }
        }
      });

      setSmartOptions((prevOptions) => {
        const isDifferent =
          !prevOptions ||
          prevOptions?.length !== newOptions?.length ||
          prevOptions?.some((option, index) => option?.flow !== newOptions[index]?.flow);

        return isDifferent ? newOptions : prevOptions;
      });
    }
  }, [id, onboardingSteps, onboardingOptions]);

  const stepData = useMemo(() => steps[parseStep(step?.name)[0]], [step]);

  const APPEARANCE_PANE = "1";

  useEffect(() => {
    if (step && step.name) {
      setIsFlowChoice(
        parseStep(step.name)[0] === steps.FLOW_CHOICE.name || parseStep(step.name)[0] === steps.DOCUMENT_REUSE.name,
      );
      setIsSmartChoice(parseStep(step.name)[0] === steps.SMART_CHOICE.name);
      setIsDocumentReuse(parseStep(step.name)[0] === steps.DOCUMENT_REUSE.name);
      setDone(parseStep(step.name)[0] === steps.DONE.name);
      if (step.options) setFlowOptions(step.options);
    }
  }, [step]);

  /* TODO: This is a hack to correctly update edges in flowChoice.
   * If a new flow is added to a flowChoice step and the user tries to connect the new flow to a element, react-flow-renderer prints a warning
   * and no edge is created. Check if there is a better solution to detect the flow change in the future.
   */
  useEffect(() => {
    if (flowOptions) {
      setFlowOptionsChanged(true);
      setTimeout(() => setFlowOptionsChanged(false), 1);
    }
  }, [flowOptions]);

  const onCloseSuspenseMenu = () => setIsMenuOpen(false);

  const onDuplicateCard = useCallback(
    (event) => {
      event.preventDefault();

      const triedToDuplicateDoneStep = doNotAllowDuplicateDoneStep(step.name);
      if (triedToDuplicateDoneStep) {
        return message.error(
          t(
            `${I18N_BASE_PATH}.methods.onDuplicateCard.duplicateDoneStepText`,
            'Não é possível duplicar a etapa "Fim" do fluxo.',
          ),
        );
      }

      const reactFlowBounds = reactFlowRef.current.getBoundingClientRect();
      const position = reactFlowInstance.project({
        x: event.clientX - reactFlowBounds.left + getRandomNumber(10, 20),
        y: event.clientY - reactFlowBounds.top + getRandomNumber(10, 20),
      });

      const rename = indexStep(onboardingSteps, parseStep(step.name)[0]);
      const newStep = { ...cleanStep(step), name: rename };

      setElements((oldElements) => [...oldElements, stepToElement(newStep, position)]);
    },
    [reactFlowRef, reactFlowInstance, setElements, onboardingSteps],
  );

  const onDeleteCard = useCallback(() => {
    const elementsToRemove = elements.filter((element) => element.id === step.name || element.source === step.name);
    const triedToRemoveDoneStep = doNotAllowRemoveDoneStep(elementsToRemove);
    if (triedToRemoveDoneStep) {
      return message.error(
        t(
          `${I18N_BASE_PATH}.methods.onDeleteCard.removeDoneStepText`,
          'Não é possível remover a etapa "Fim" do fluxo, pois é obrigatória',
        ),
      );
    }

    const newElements = removeElements(elementsToRemove, [...elements]);
    const newElementsUpdated = updateElementStepData(newElements);
    setElements(newElementsUpdated);
  }, [elements, setElements, t]);

  useEffect(() => {
    updateNodeInternals(id);
  }, [elements]);

  const renderSuspenseMenu = () => (
    <SuspenseMenu isOpen={isMenuOpen} onClose={onCloseSuspenseMenu}>
      <MenuOptionsDefault
        shouldRenderDuplicateAndDeleteAction={isTemplateDefault}
        isSmartChoice={isSmartChoice}
        handleDeleteCard={() => {
          hideSidebar();
          onDeleteCard();
          onCloseSuspenseMenu();
        }}
        handleShowCard={() => {
          hideSidebar();
          setActivePane(APPEARANCE_PANE);
          onCloseSuspenseMenu();
        }}
        handleDuplicateCard={(event) => {
          hideSidebar();
          onDuplicateCard(event);
          onCloseSuspenseMenu();
        }}
      />
    </SuspenseMenu>
  );

  return (
    <div
      className={classNames(
        `relative bg-gray-300 min-w-48 w-full transition`,
        step.isEnabled ? "opacity-100" : "opacity-30",
        selected ? "shadow-lg-selected" : "shadow-lg ",
        { "pb-px": flowOptionsChanged } /* TODO: Part of the hack to correctly update edges in flowChoice */,
      )}
      style={{
        borderRadius: isSmartChoice || isDocumentReuse ? "8px" : "4px",
        background: isSmartChoice && "linear-gradient(90deg, #004AF7 0%, #34D690 126.72%)",
        padding: isSmartChoice && "3px",
      }}
      onMouseLeave={onCloseSuspenseMenu}
    >
      {step.flow && (
        <div className="absolute -top-5 left-1 text-white font-bold flex leading-5">
          {Array.isArray(step.flow) ? (
            step.flow.map((flow) => (
              <span key={`${id}_FLOW_${flow}`} className="px-1 rounded-t-md" style={{ background: getFlowColor(flow) }}>
                {flow}
              </span>
            ))
          ) : (
            <span className="px-1 rounded-t-md" style={{ background: getFlowColor(step.flow) }}>
              {step.flow}
            </span>
          )}
        </div>
      )}
      <div
        className={"bg-white px-3 text-black py-1.5 flex items-center"}
        style={{
          borderRadius: isSmartChoice || isDocumentReuse ? "8px 8px 0 0 " : "4px 4px 0 0",
          background: isSmartChoice || isDocumentReuse ? "linear-gradient(90deg, #004AF7 0%, #34D690 126.72%)" : "",
        }}
      >
        <aside
          className="w-full whitespace-nowrap flex items-center"
          style={{ color: isSmartChoice || isDocumentReuse ? "white" : "" }}
        >
          <StepIcon Src={stepData.emoji} />
          <span
            className="font-bold whitespace-nowrap ml-1.5 overflow-ellipsis overflow-hidden"
            style={{ maxWidth: "10rem" }}
          >
            {t(`general.steps.${parseStep(step.name)[0]}.description`, step.description)}
          </span>
        </aside>
        <aside className="flex items-end self-end w-full justify-end px-1.5">
          <span
            className={classNames(
              "bg-gray-200 rounded-full flex items-center justify-center transition-all z-10 hover: cursor-pointer",
              { "bg-gray-300": isMenuOpen },
            )}
            style={{
              background: isSmartChoice || isDocumentReuse ? "none" : "",
              color: isSmartChoice || isDocumentReuse ? "white" : "",
            }}
            onClick={() => {
              showSidebar();
              setIsMenuOpen((currentValue) => !currentValue);
            }}
          >
            <Icon icon="more-horizontal" />
          </span>
        </aside>
      </div>
      {isMenuOpen && renderSuspenseMenu()}
      <div className="p-1 flex flex-col gap-1">
        {!isFlowChoice && !isSmartChoice ? (
          <div className="relative w-full h-full bg-blue-500 text-white rounded-sm">
            <div className="flex content-between w-full p-3">
              <span>
                {isDone
                  ? t(`${I18N_BASE_PATH}.components.finalize`, "Finalizar")
                  : t(`${I18N_BASE_PATH}.components.next`, "Continuar")}
              </span>
              <span></span>
            </div>
            {!isDone && (
              <Handle
                type="source"
                position="right"
                style={{ background: "var(--color-caf-primary)", height: 20, width: 20, right: -10, borderWidth: 2 }}
              />
            )}
          </div>
        ) : (
          <>
            {!isSmartChoice ? (
              <>
                {!flowOptions?.filter((opt) => opt.flow).length && (
                  <div className="relative w-full h-full p-3 flex items-center justify-center">
                    <span>{t(`${I18N_BASE_PATH}.components.noneOfOptions`, "Nenhuma opção")}</span>
                    <Handle
                      type="source"
                      position="right"
                      style={{
                        background: "var(--color-caf-primary)",
                        height: 20,
                        width: 20,
                        right: -10,
                        borderWidth: 2,
                      }}
                    />
                  </div>
                )}
                {flowOptions
                  ?.filter((opt) => opt.flow)
                  .map((opt) => (
                    <div
                      key={`FLOW_OPT_${opt.flow}`}
                      className="text-white rounded-sm w-full h-full relative"
                      style={{ background: getFlowColor(opt.flow) }}
                    >
                      <div className="flex content-between w-full p-3">
                        {opt.text ? (
                          <span>{t(opt.text, opt.text)}</span>
                        ) : (
                          <span className="opacity-50">
                            {t(`${I18N_BASE_PATH}.components.withoutText`, "Sem texto")}
                          </span>
                        )}
                      </div>
                      <Handle
                        id={opt.flow}
                        type="source"
                        position="right"
                        style={{
                          background: "var(--color-caf-primary)",
                          height: 20,
                          width: 20,
                          right: -10,
                          borderWidth: 2,
                        }}
                      />
                    </div>
                  ))}
              </>
            ) : (
              <>
                {smartOptions.map((opt) => (
                  <div
                    key={`FLOW_OPT_${opt.flow}`}
                    className="rounded-sm w-full h-full relative"
                    style={{
                      background: "#ffffff",
                      borderRadius: "8px",
                      color: "#0D379B",
                      fontWeight: "700",
                    }}
                  >
                    <div className="flex content-between w-full p-3">
                      {opt.text ? (
                        <span>
                          {t(
                            `onboardingProps.defaultOnboardingValues.defaultDocumentTypesOptions.${opt.text.toLowerCase()}.title`,
                          )}
                        </span>
                      ) : (
                        <span className="opacity-50">{t(`${I18N_BASE_PATH}.components.withoutText`, "Sem texto")}</span>
                      )}
                    </div>
                    <Handle
                      id={opt.flow}
                      type="source"
                      position="right"
                      style={{
                        background: getFlowColor(opt.flow),
                        height: 20,
                        width: 20,
                        right: -10,
                        borderWidth: 2,
                      }}
                    />
                  </div>
                ))}
              </>
            )}
          </>
        )}
      </div>
      {/* Handle spans the whole node but is only interable when "isConnecting" */}
      <Handle
        type="target"
        position="left"
        className={`opacity-0 ${isConnecting ? "" : "!pointer-events-none"}`}
        style={{ borderRadius: "4px", width: "100%", height: "100%", left: 0 }}
      />
    </div>
  );
};

export default DefaultStepNode;
