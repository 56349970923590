import Onboarding from "@package/steps";
import { parseStep } from "@utils/onboarding";
import { listContainsDuplicatedValues } from "@utils/template-validations/general-validations";
import { isSequential } from "@utils/template-validations/steps-validations/utils";
import {
  templateValidationsMessages,
  validateTemplateMessages,
} from "@utils/template-validations/templateValidationsMessages";
import isEqual from "lodash.isequal";

const { steps } = Onboarding;

// Merging all flow names options into just one list
const mergeAllFlowOptions = (onboardingSteps = [], flowNames = []) => {
  if (!onboardingSteps?.length) {
    return;
  }

  onboardingSteps
    ?.filter(
      (_step) =>
        isEqual(parseStep(_step?.name)[0], steps.FLOW_CHOICE.name) ||
        isEqual(parseStep(_step?.name)[0], steps.DOCUMENT_REUSE.name),
    )
    ?.map((_step) => !!_step?.options && _step?.options)
    ?.forEach((options) => options?.forEach((option) => flowNames.push(option?.flow)));
};

// Check duplicated steps that can't be duplicated in the same flow
export const validateDuplicatedStepsInTheSameFlow = (
  onboardingOptions = {},
  supportedUniquePFSteps = [],
  supportedUniquePJSteps = [],
  templateValidationNotificationsToAdd = () => [],
) => {
  const uniqueSupportedSteps = isEqual(onboardingOptions?.type, "PJ")
    ? [...supportedUniquePFSteps, ...supportedUniquePJSteps]
    : supportedUniquePFSteps;

  // Init with one null flow to simulate the default flow
  let flows = [null];

  mergeAllFlowOptions(onboardingOptions?.steps, flows);

  const checksForDuplicateSteps = (flow = null) => {
    const duplicatedStepsInTheSameFlow = [];

    uniqueSupportedSteps.forEach((_uniqueStep) => {
      const stepsInTemplate = onboardingOptions?.steps?.filter(
        (_step) =>
          !_step?.components &&
          isEqual(parseStep(_step?.name)[0], parseStep(_uniqueStep?.name)[0]) &&
          ((!flow && !_step?.flow) || (flow && _step?.flow?.includes(flow))),
      );

      // verifies if the same step is two or more times in the same flow
      if (stepsInTemplate?.length > 1) duplicatedStepsInTheSameFlow.push(_uniqueStep?.description);
    });

    return duplicatedStepsInTheSameFlow;
  };

  // if has duplicated steps (that aren't supported for duplicate) in the same flow, add a template notification
  for (const flow of flows) {
    checksForDuplicateSteps(flow).forEach((_duplicated) => {
      templateValidationNotificationsToAdd.push(
        validateTemplateMessages(templateValidationsMessages.STEPS_DUPLICATED)(_duplicated, flow),
      );
    });
  }
};

// Check for identical flow names when add a new FLOW_CHOICE step
export const validateDuplicatedTemplateFlowNames = (
  onboardingSteps = [],
  templateValidationNotificationsToAdd = () => [],
) => {
  let hasDuplicatedFlowNames;
  let flowsNames = [];

  mergeAllFlowOptions(onboardingSteps, flowsNames);

  const flowChoiceSteps = onboardingSteps
    ?.map((_step) => {
      if (_step?.name?.includes("FLOW_CHOICE") || _step?.name?.includes("DOCUMENT_REUSE")) {
        return _step;
      }
      return null;
    })
    .filter((step) => step !== null && step !== undefined);

  flowChoiceSteps?.forEach((flowChoiceStep) => {
    if (flowChoiceStep?.options?.length === 0) {
      templateValidationNotificationsToAdd.push(
        validateTemplateMessages(templateValidationsMessages.WITHOUT_FLOW_OPTIONS),
      );
    }
  });

  // validate if there are duplicated flow names in the same FLOW_CHOICE step or in different FLOW_CHOICE steps
  if (!!flowsNames?.length) {
    hasDuplicatedFlowNames = listContainsDuplicatedValues(flowsNames);
  }

  // if has duplicated flow names, add a template notification
  if (hasDuplicatedFlowNames) {
    // remove empty objects from options
    onboardingSteps = onboardingSteps?.map((step) => {
      if (step?.name?.includes("FLOW_CHOICE") || step?.name?.includes("DOCUMENT_REUSE")) {
        const options = step?.options;
        step.options = options.filter((obj) => Object.keys(obj).length > 0);
        return step;
      }
      return step;
    });

    if (!!flowsNames?.length) {
      // remove undefined values from the flows list
      const flowNamesWithoutUndefined = flowsNames.filter((flowName) => flowName !== undefined);
      // check for duplicate names in the flows
      const newHasDuplicatedFlowNames = listContainsDuplicatedValues(flowNamesWithoutUndefined);

      // if has duplicated flow names, add a template notification
      if (newHasDuplicatedFlowNames && flowNamesWithoutUndefined?.length > 1) {
        templateValidationNotificationsToAdd.push(
          validateTemplateMessages(templateValidationsMessages.FLOW_NAMES_DUPLICATED),
        );
      }
    }
  }
};

export const validateSmartChoiceShouldHaveDocumentType = (
  smartChoiceFlowsStepsIndexes = [],
  documentTypeFlowsStepsIndexes = [],
  templateValidationNotificationsToAdd = () => [],
) => {
  const templateHasSmartChoice = !!smartChoiceFlowsStepsIndexes?.length;
  const templateHasDocumentType = !!documentTypeFlowsStepsIndexes?.length;
  const stepIndexs = [documentTypeFlowsStepsIndexes[0]?.stepIndex, smartChoiceFlowsStepsIndexes[0]?.stepIndex];

  if ((!templateHasDocumentType && templateHasSmartChoice) || (!isSequential(stepIndexs) && templateHasSmartChoice)) {
    templateValidationNotificationsToAdd.push(
      validateTemplateMessages(templateValidationsMessages.SMART_CHOICE_SHOULD_BE_DOCUMENT_TYPE)(),
    );
  } else if (!templateHasSmartChoice) {
    return;
  }
};

export const validateSmartChoiceFlows = (
  steps = [],
  documentTypes = [],
  templateValidationNotificationsToAdd = () => [],
) => {
  const getFlowFromStep = (_step) => _step?.flow;
  const getNameFromStep = (_step) => _step?.name;

  const cameraAccessSteps = steps.filter(
    (_step) => getNameFromStep(_step)?.includes("CAMERA_ACCESS") && _step.isEnabled,
  );
  const hasSmartChoiceStep = steps.some((_step) => getNameFromStep(_step)?.includes("SMART_CHOICE"));

  const cameraAccessFlowsCount = cameraAccessSteps
    ?.map((_step) => _step?.flow?.length || 0)
    ?.reduce((accumulator, currentValue) => accumulator + currentValue, 0);

  const documentTypeLength = documentTypes.length;

  const documentTypesFlows = documentTypes.map((documentType) => documentType?.type);

  const uniqueFlows = [
    ...new Set(
      steps
        .filter((_step) => !getNameFromStep(_step)?.includes("SMART_CHOICE") && getFlowFromStep(_step))
        .flatMap((_step) => getFlowFromStep(_step)),
    ),
  ];

  const notAllFlowsAreConnected = !documentTypesFlows.every((flow) => uniqueFlows.includes(flow));

  const addNotification = (message) => {
    templateValidationNotificationsToAdd.push(validateTemplateMessages(message)());
  };

  if (notAllFlowsAreConnected && hasSmartChoiceStep) {
    addNotification(templateValidationsMessages.DOCUMENT_TYPES_SHOULD_BE_IN_FLOWS);
  }

  if (cameraAccessFlowsCount < documentTypeLength && cameraAccessFlowsCount > 0 && hasSmartChoiceStep) {
    addNotification(templateValidationsMessages.HAS_CAMERA_ACCESS_ON_ALL_FLOWS);
  }
};
