import { useAPIContext } from "@contexts/API";
import { useOnboardingOptionsContext } from "@contexts/OnboardingOptions";
import { useOnboardingStepsContext } from "@contexts/OnboardingSteps";
import Onboarding from "@package/steps";
import templateValidations from "@utils/template-validations";
import { createContext, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import defaultValues from "./defaultValues";

const { steps } = Onboarding;

/** Create, validate and show template validations messages */
export const TemplateValidationsContext = createContext(defaultValues);
export const useTemplateValidationsContext = () => useContext(TemplateValidationsContext);

export const TemplateValidationsProvider = ({ children }) => {
  const { t } = useTranslation();

  const { onboardingOptions } = useOnboardingOptionsContext();
  const { supportedUniquePJSteps, supportedUniquePFSteps } = useOnboardingStepsContext();

  const [isValidationListVisible, setIsValidationListVisible] = useState(false);
  const [hasToAnimateButton, setHasToAnimateValidationButton] = useState(false);
  const [templateValidationsNotifications, setTemplateValidationsNotifications] = useState([]);

  useEffect(() => {
    const templateValidationNotificationsToAdd = [];

    /* * Variables validations */
    templateValidations.GENERAL_TEMPLATE_VALIDATIONS.validateDuplicatedTemplateVariablesNames(
      onboardingOptions?.variables,
      templateValidationNotificationsToAdd,
    );

    templateValidations.GENERAL_TEMPLATE_VALIDATIONS.validateTemplateVariablesNames(
      onboardingOptions?.variables,
      templateValidationNotificationsToAdd,
    );

    /*
      Other validations
    */
    templateValidations.GENERAL_TEMPLATE_VALIDATIONS.validateDomain(
      onboardingOptions?.domain,
      templateValidationNotificationsToAdd,
    );

    templateValidations.GENERAL_TEMPLATE_VALIDATIONS.validateHelpButtonLink(
      onboardingOptions?.helpButtonLink,
      templateValidationNotificationsToAdd,
    );

    /* Steps validations */

    // General validations
    templateValidations.STEPS_VALIDATIONS.FLOW.validateDuplicatedStepsInTheSameFlow(
      onboardingOptions,
      supportedUniquePFSteps,
      supportedUniquePJSteps,
      templateValidationNotificationsToAdd,
    );

    /*
      PF validations
    */

    const defaultFlowName = t("general.label.defaultFlowName", "padrão");

    /* Steps Flows Indexes */
    // PF steps
    const documentTypeFlowsStepsIndexes = templateValidations.STEPS_VALIDATIONS.UTILS.filterFlowsStepsIndexes(
      onboardingOptions?.steps,
      steps.DOCUMENT_TYPE.name,
      defaultFlowName,
    );
    const sendDocumentTypeFlowsStepsIndexes = templateValidations.STEPS_VALIDATIONS.UTILS.filterFlowsStepsIndexes(
      onboardingOptions?.steps,
      steps.SEND_DOCUMENT_TYPE.name,
      defaultFlowName,
    );
    const documentIssuedCountryFlowsStepsIndexes = templateValidations.STEPS_VALIDATIONS.UTILS.filterFlowsStepsIndexes(
      onboardingOptions?.steps,
      steps.DOCUMENT_ISSUED_COUNTRY.name,
      defaultFlowName,
    );
    const usingTermsFlowsStepsIndexes = templateValidations.STEPS_VALIDATIONS.UTILS.filterFlowsStepsIndexes(
      onboardingOptions?.steps,
      steps.USING_TERMS.name,
      defaultFlowName,
    );
    const documentReuseFlowsStepsIndexes = templateValidations.STEPS_VALIDATIONS.UTILS.filterFlowsStepsIndexes(
      onboardingOptions?.steps,
      steps.DOCUMENT_REUSE.name,
      defaultFlowName,
    );
    const cameraAccessFlowsStepsIndexes = templateValidations.STEPS_VALIDATIONS.UTILS.filterFlowsStepsIndexes(
      onboardingOptions?.steps,
      steps.CAMERA_ACCESS.name,
      defaultFlowName,
    );
    const DDFlowsStepsIndexes = templateValidations.STEPS_VALIDATIONS.UTILS.filterFlowsStepsIndexes(
      onboardingOptions?.steps,
      steps.DD.name,
      defaultFlowName,
    );
    const PFLFlowsStepsIndexes = templateValidations.STEPS_VALIDATIONS.UTILS.filterFlowsStepsIndexes(
      onboardingOptions?.steps,
      steps.PFL.name,
      defaultFlowName,
    );
    const FAFlowsStepsIndexes = templateValidations.STEPS_VALIDATIONS.UTILS.filterFlowsStepsIndexes(
      onboardingOptions?.steps,
      steps.FA.name,
      defaultFlowName,
    );
    const ClearSaleFlowsStepsIndexes = templateValidations.STEPS_VALIDATIONS.UTILS.filterFlowsStepsIndexes(
      onboardingOptions?.steps,
      steps.LIVENESS_CLEARSALE.name,
      defaultFlowName,
    );
    const Facetec3DFlowsStepsIndexes = templateValidations.STEPS_VALIDATIONS.UTILS.filterFlowsStepsIndexes(
      onboardingOptions?.steps,
      steps.LIVENESS_FACETEC_3D.name,
      defaultFlowName,
    );
    const Facetec2DFlowsStepsIndexes = templateValidations.STEPS_VALIDATIONS.UTILS.filterFlowsStepsIndexes(
      onboardingOptions?.steps,
      steps.LIVENESS_FACETEC_2D.name,
      defaultFlowName,
    );
    const Facetec3DFAFlowsStepsIndexes = templateValidations.STEPS_VALIDATIONS.UTILS.filterFlowsStepsIndexes(
      onboardingOptions?.steps,
      steps.FACE_AUTH_FACETEC_3D.name,
      defaultFlowName,
    );
    const Facetec2DFAFlowsStepsIndexes = templateValidations.STEPS_VALIDATIONS.UTILS.filterFlowsStepsIndexes(
      onboardingOptions?.steps,
      steps.FACE_AUTH_FACETEC_2D.name,
      defaultFlowName,
    );
    const IProovFlowsStepsIndexes = templateValidations.STEPS_VALIDATIONS.UTILS.filterFlowsStepsIndexes(
      onboardingOptions?.steps,
      steps.LIVENESS_IPROOV.name,
      defaultFlowName,
    );
    const IProovFAFlowsStepsIndexes = templateValidations.STEPS_VALIDATIONS.UTILS.filterFlowsStepsIndexes(
      onboardingOptions?.steps,
      steps.FACE_AUTH_IPROOV.name,
      defaultFlowName,
    );
    const dataConfirmationFlowsStepsIndexes = templateValidations.STEPS_VALIDATIONS.UTILS.filterFlowsStepsIndexes(
      onboardingOptions?.steps,
      steps.DATA_CONFIRMATION.name,
      defaultFlowName,
    );
    const PF_PF_DATAFlowsStepsIndexes = templateValidations.STEPS_VALIDATIONS.UTILS.filterFlowsStepsIndexes(
      onboardingOptions?.steps,
      steps.PF_PF_DATA.name,
      defaultFlowName,
    );
    const emailValidationFlowsStepsIndexes = templateValidations.STEPS_VALIDATIONS.UTILS.filterFlowsStepsIndexes(
      onboardingOptions?.steps,
      steps.EMAIL_VALIDATION.name,
      defaultFlowName,
    );
    const phoneNumberValidationFlowsStepsIndexes = templateValidations.STEPS_VALIDATIONS.UTILS.filterFlowsStepsIndexes(
      onboardingOptions?.steps,
      steps.PHONE_NUMBER_VALIDATION.name,
      defaultFlowName,
    );
    const smartChoiceFlowsStepsIndexes = templateValidations.STEPS_VALIDATIONS.UTILS.filterFlowsStepsIndexes(
      onboardingOptions?.steps,
      steps.SMART_CHOICE.name,
      defaultFlowName,
    );
    // PJ steps
    const companyDataFlowsStepsIndexes = templateValidations.STEPS_VALIDATIONS.UTILS.filterFlowsStepsIndexes(
      onboardingOptions?.steps,
      steps.COMPANY_DATA.name,
      defaultFlowName,
    );
    const companyAddressFlowsStepsIndexes = templateValidations.STEPS_VALIDATIONS.UTILS.filterFlowsStepsIndexes(
      onboardingOptions?.steps,
      steps.BUSINESS_ADDRESS.name,
      defaultFlowName,
    );

    const cpfValidationFlowsStepsIndexes = templateValidations.STEPS_VALIDATIONS.UTILS.filterFlowsStepsIndexes(
      onboardingOptions?.steps,
      steps.CPF_VALIDATION.name,
      defaultFlowName,
    );

    /* General reused data */
    const emailValidationFlows = emailValidationFlowsStepsIndexes?.map((flowStep) => flowStep?.flow);
    const phoneNumberValidationFlows = phoneNumberValidationFlowsStepsIndexes?.map((flowStep) => flowStep?.flow);
    const dataConfirmationFlows = dataConfirmationFlowsStepsIndexes?.map((flowStep) => flowStep?.flow);

    // UPLOAD_DOCUMENTS step validations
    templateValidations.STEPS_VALIDATIONS.UPLOAD_DOCUMENTS.checkIdenticalOTHERFieldsTitleAtUploadDocumentsStep(
      onboardingOptions?.steps,
      templateValidationNotificationsToAdd,
    );

    // CPF_VALIDATION step validations
    templateValidations.STEPS_VALIDATIONS.CPF_VALIDATION.cpfValidationStep(
      cpfValidationFlowsStepsIndexes,
      IProovFlowsStepsIndexes,
      IProovFAFlowsStepsIndexes,
      PFLFlowsStepsIndexes,
      FAFlowsStepsIndexes,
      ClearSaleFlowsStepsIndexes,
      templateValidationNotificationsToAdd,
    );

    // FLOW_CHOICE step validations
    templateValidations.STEPS_VALIDATIONS.FLOW.validateDuplicatedTemplateFlowNames(
      onboardingOptions?.steps,
      templateValidationNotificationsToAdd,
    );

    // SMART_CHOICE step validations
    templateValidations.STEPS_VALIDATIONS.FLOW.validateSmartChoiceShouldHaveDocumentType(
      smartChoiceFlowsStepsIndexes,
      documentTypeFlowsStepsIndexes,
      templateValidationNotificationsToAdd,
    );

    // SMART_CHOICE step validations
    templateValidations.STEPS_VALIDATIONS.CAMERA_ACCESS.validateSmartChoiceFlows(
      onboardingOptions?.steps,
      onboardingOptions?.documentTypes,
      templateValidationNotificationsToAdd,
    );

    // CUSTOM_FORM step validations
    templateValidations.STEPS_VALIDATIONS.CUSTOM_FORM.customFormAndItsFieldsHaveIdentifier(
      onboardingOptions?.steps,
      templateValidationNotificationsToAdd,
      defaultFlowName,
    );

    templateValidations.STEPS_VALIDATIONS.CUSTOM_FORM.customFormHasDuplicatedIds(
      onboardingOptions?.steps,
      templateValidationNotificationsToAdd,
      defaultFlowName,
    );

    // DOCUMENT_TYPE step validations
    templateValidations.STEPS_VALIDATIONS.DOCUMENT_TYPE.documentTypeStepMustHasAtLeastOneDocumentType(
      onboardingOptions,
      templateValidationNotificationsToAdd,
    );

    // QSA step validations
    templateValidations.STEPS_VALIDATIONS.QSA.qsaStepHasBothFlagsOn(
      onboardingOptions?.steps,
      templateValidationNotificationsToAdd,
    );

    templateValidations.STEPS_VALIDATIONS.DOCUMENT_TYPE.documentTypeStepHasDocumentTypeAny(
      onboardingOptions,
      templateValidationNotificationsToAdd,
    );

    templateValidations.STEPS_VALIDATIONS.DOCUMENT_TYPE.documentTypeStepMustHaveAtLeastOneRequiredUseTerms(
      onboardingOptions?.steps,
      templateValidationNotificationsToAdd,
    );

    templateValidations.STEPS_VALIDATIONS.DOCUMENT_TYPE.documentTypeStepMustBeBeforeDDStep(
      documentTypeFlowsStepsIndexes,
      DDFlowsStepsIndexes,
      templateValidationNotificationsToAdd,
    );

    templateValidations.STEPS_VALIDATIONS.DOCUMENT_TYPE.templateShouldHaveDocumentTypeOrDocumentIssuedCountryIfDDIsEnabled(
      DDFlowsStepsIndexes,
      documentTypeFlowsStepsIndexes,
      documentIssuedCountryFlowsStepsIndexes,
      onboardingOptions?.steps,
      templateValidationNotificationsToAdd,
    );

    templateValidations.STEPS_VALIDATIONS.DD.templateShouldHaveDocumentTypeOrAutoDocumentSdkDD(
      DDFlowsStepsIndexes,
      documentTypeFlowsStepsIndexes,
      onboardingOptions?.steps,
      templateValidationNotificationsToAdd,
    );

    templateValidations.STEPS_VALIDATIONS.DD.templateShouldHaveDocumentsType(
      onboardingOptions?.steps,
      templateValidationNotificationsToAdd,
    );

    templateValidations.STEPS_VALIDATIONS.ATTORNEY.hasPowerOfAttorney(
      onboardingOptions?.steps,
      templateValidationNotificationsToAdd,
    );

    // USING_TERMS step validations

    templateValidations.STEPS_VALIDATIONS.USING_TERMS.usingTermsStepMustHaveAtLeastOneRequiredUseTerms(
      onboardingOptions?.steps,
      templateValidationNotificationsToAdd,
    );

    templateValidations.STEPS_VALIDATIONS.USING_TERMS.usingTermsStepMustHaveDocumentReuseIfRequired(
      onboardingOptions?.steps,
      usingTermsFlowsStepsIndexes,
      documentReuseFlowsStepsIndexes,
      templateValidationNotificationsToAdd,
    );

    // SEND_DOCUMENT_TYPE step validations

    templateValidations.STEPS_VALIDATIONS.SEND_DOCUMENT_TYPE.sendDocumentTypeStepMustBeBeforeDDStep(
      sendDocumentTypeFlowsStepsIndexes,
      DDFlowsStepsIndexes,
      templateValidationNotificationsToAdd,
    );

    templateValidations.STEPS_VALIDATIONS.SEND_DOCUMENT_TYPE.templateHasNotDDsendDocumentTypeMustNotBeEnabled(
      DDFlowsStepsIndexes,
      sendDocumentTypeFlowsStepsIndexes,
      templateValidationNotificationsToAdd,
    );

    templateValidations.STEPS_VALIDATIONS.SEND_DOCUMENT_TYPE.sendDocumentTypeStepMustBeAfterDocumentTypeStep(
      documentTypeFlowsStepsIndexes,
      sendDocumentTypeFlowsStepsIndexes,
      templateValidationNotificationsToAdd,
    );

    // CAMERA_ACCESS step validations
    templateValidations.STEPS_VALIDATIONS.CAMERA_ACCESS.cameraAccessStepMustBeBeforeDDandPFLSteps(
      cameraAccessFlowsStepsIndexes,
      DDFlowsStepsIndexes,
      [
        PFLFlowsStepsIndexes,
        FAFlowsStepsIndexes,
        ClearSaleFlowsStepsIndexes,
        Facetec3DFlowsStepsIndexes,
        Facetec2DFlowsStepsIndexes,
        Facetec3DFAFlowsStepsIndexes,
        Facetec2DFAFlowsStepsIndexes,
        IProovFlowsStepsIndexes,
        IProovFAFlowsStepsIndexes,
      ],
      templateValidationNotificationsToAdd,
    );

    templateValidations.STEPS_VALIDATIONS.CAMERA_ACCESS.templateHasNotDDorPFLStepCameraAccessShouldNotBeEnabled(
      [
        DDFlowsStepsIndexes,
        PFLFlowsStepsIndexes,
        FAFlowsStepsIndexes,
        ClearSaleFlowsStepsIndexes,
        Facetec3DFlowsStepsIndexes,
        Facetec2DFlowsStepsIndexes,
        Facetec3DFAFlowsStepsIndexes,
        Facetec2DFAFlowsStepsIndexes,
        IProovFlowsStepsIndexes,
        IProovFAFlowsStepsIndexes,
      ],
      cameraAccessFlowsStepsIndexes,
      templateValidationNotificationsToAdd,
    );

    templateValidations.STEPS_VALIDATIONS.CAMERA_ACCESS.templateShouldHasCameraAccessIfDDorPFL(
      [
        DDFlowsStepsIndexes,
        PFLFlowsStepsIndexes,
        FAFlowsStepsIndexes,
        ClearSaleFlowsStepsIndexes,
        Facetec3DFlowsStepsIndexes,
        Facetec2DFlowsStepsIndexes,
        Facetec3DFAFlowsStepsIndexes,
        Facetec2DFAFlowsStepsIndexes,
        IProovFlowsStepsIndexes,
        IProovFAFlowsStepsIndexes,
      ],
      cameraAccessFlowsStepsIndexes,
      templateValidationNotificationsToAdd,
    );

    // DATA_CONFIRMATION step validations
    templateValidations.STEPS_VALIDATIONS.DATA_CONFIRMATION.dataConfirmationStepShouldBeAfterDDStep(
      DDFlowsStepsIndexes,
      dataConfirmationFlowsStepsIndexes,
      templateValidationNotificationsToAdd,
    );

    templateValidations.STEPS_VALIDATIONS.DATA_CONFIRMATION.dataConfirmationStepMustBeInCaseOfEmailValidationStep(
      dataConfirmationFlowsStepsIndexes,
      emailValidationFlowsStepsIndexes,
      emailValidationFlows,
      dataConfirmationFlows,
      templateValidationNotificationsToAdd,
    );

    templateValidations.STEPS_VALIDATIONS.DATA_CONFIRMATION.dataConfirmationStepMustHaveEmailFieldInCaseOfEmailValidationStep(
      emailValidationFlowsStepsIndexes,
      emailValidationFlows,
      onboardingOptions?.steps,
      templateValidationNotificationsToAdd,
      defaultFlowName,
    );

    templateValidations.STEPS_VALIDATIONS.DATA_CONFIRMATION.dataConfirmationStepMustBeInCaseOfPhoneNumberValidationStep(
      phoneNumberValidationFlowsStepsIndexes,
      dataConfirmationFlowsStepsIndexes,
      phoneNumberValidationFlows,
      dataConfirmationFlows,
      templateValidationNotificationsToAdd,
    );

    templateValidations.STEPS_VALIDATIONS.DATA_CONFIRMATION.dataConfirmationMustHasPhoneNumberFieldInCaseOfPhoneNumberStep(
      phoneNumberValidationFlowsStepsIndexes,
      phoneNumberValidationFlows,
      onboardingOptions?.steps,
      templateValidationNotificationsToAdd,
      defaultFlowName,
    );

    // PF_PF_DATA step validations
    templateValidations.STEPS_VALIDATIONS.PF_PF_DATA.PFPFDataStepMustHaveEmailOrPhoneNumberField(
      PF_PF_DATAFlowsStepsIndexes,
      onboardingOptions?.steps,
      templateValidationNotificationsToAdd,
    );

    /*
      PJ validations
    */

    // COMPANY_DATA step validations
    templateValidations.STEPS_VALIDATIONS.COMPANY_DATA.companyDataStepMustBeBeforeCompanyAddressStep(
      companyDataFlowsStepsIndexes,
      companyAddressFlowsStepsIndexes,
      templateValidationNotificationsToAdd,
    );

    templateValidations.STEPS_VALIDATIONS.COMPANY_DATA.companyDataStepMustExist(
      onboardingOptions,
      templateValidationNotificationsToAdd,
    );

    setTemplateValidationsNotifications(templateValidationNotificationsToAdd);
  }, [onboardingOptions]);

  return (
    <TemplateValidationsContext.Provider
      value={{
        isValidationListVisible,
        setIsValidationListVisible,
        templateValidationsNotifications,
        setTemplateValidationsNotifications,
        hasToAnimateButton,
        setHasToAnimateValidationButton,
      }}
    >
      {children}
    </TemplateValidationsContext.Provider>
  );
};
