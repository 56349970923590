import { Button, Icon } from "@combateafraude/react";
import Onboarding from "@package/steps";
import { Divider } from "antd";
import classNames from "classnames";
import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";

const { OnboardingStepsContext } = Onboarding;

const I18N_BASE_PATH = "src.components.navigationArrows";

const NavigationArrows = () => {
  const { t } = useTranslation();

  const { goForward, goBackward, currentIndex, onboardingSteps } = useContext(OnboardingStepsContext);

  const onboardingStepsFilter = useMemo(() => {
    return onboardingSteps?.filter((step) => {
      return !step.name.includes("SMART_CHOICE") && !step.name.includes("DOCUMENT_REUSE");
    });
  }, [onboardingSteps]);

  const currentStepName = useMemo(
    () => onboardingStepsFilter?.find((_, index) => currentIndex === index)?.description || "",
    [onboardingStepsFilter, currentIndex],
  );

  const previousNextOnboardingSteps = useMemo(() => {
    const previousStepName = onboardingStepsFilter?.find((_, index) => currentIndex - 1 === index)?.description || "";
    const nextStepName = onboardingStepsFilter?.find((_, index) => currentIndex + 1 === index)?.description || "";

    return {
      previousStepName,
      nextStepName,
    };
  }, [currentIndex, onboardingStepsFilter]);

  return (
    <div
      className="fixed z-50 bottom-6 left-48 flex flex-col w-full items-center"
      style={{
        transform: "scale(.75)",
        height: "30px",
      }}
    >
      <strong className="font-semibold text-lg text-black text-center min-w-8">"{currentStepName}"</strong>
      <div className="flex justify-center items-center">
        <Button
          rounded
          size="middle"
          className={`mx-4 !shadow-sm`}
          outlined={false}
          onClick={goBackward}
          shape="circle"
          icon={<Icon icon="arrow_left" className="hover:text-caf-primary transition-colors" />}
          disabled={currentIndex === 0}
        />
        <div className="flex flex-row gap-4 items-center justify-center">
          <NavigationButton onClick={goBackward} disabled={currentIndex === 0}>
            {!!previousNextOnboardingSteps?.previousStepName
              ? t(`${I18N_BASE_PATH}.page`, "", {
                  index: currentIndex,
                })
              : t(`${I18N_BASE_PATH}.start`)}
          </NavigationButton>
          <Divider type="vertical" />
          <NavigationButton onClick={goForward} disabled={currentIndex === onboardingStepsFilter?.length - 1}>
            {!!previousNextOnboardingSteps?.nextStepName
              ? t(`${I18N_BASE_PATH}.page`, "", {
                  index: currentIndex + 2,
                })
              : t(`${I18N_BASE_PATH}.end`)}
          </NavigationButton>
        </div>
        <Button
          rounded
          size="middle"
          className={`mx-4 !shadow-sm`}
          outlined={false}
          onClick={goForward}
          shape="circle"
          icon={<Icon icon="arrow_right" className="hover:text-caf-primary transition-colors" />}
          disabled={currentIndex === onboardingStepsFilter?.length - 1}
        />
      </div>
    </div>
  );
};

const NavigationButton = (props) => (
  <button
    onClick={props.onClick}
    className={classNames(
      "font-medium text-base text-black opacity-30 min-w-16 text-center transition-opacity duration-150 focus:outline-none",
      {
        "hover:opacity-50": !props.disabled,
        "cursor-default": props.disabled,
      },
    )}
    disabled={props.disabled}
  >
    {props.children}
  </button>
);

export default NavigationArrows;
