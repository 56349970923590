import { shouldNotRenderErrorsInBusinessAddressWhenKYBTheme } from "./should_not_render_errors_in_business_address_when_kyb_theme";
import { shouldRenderDocumentAutomaticCaptureSettingsJustInBrazilianFlow } from "./should_render_document_automatic_capture_settings";
import { shouldRenderSmartDDInput } from "./should_render_smartdd_input";
import { shouldRenderSelectDocs } from "./should_render_select_docs";
import { shouldRenderPreviewPDFSwitchWhenSendDocumentTypeStepIsUsedFlow } from "./should_render_preview_pdf_switch";
import { shouldRenderIfStepSendDocumentTypeIsUsedOnFlow } from "./step_custom-labels-dd-when-send-document-type";
import { shouldRenderPreviewDD } from "./should_render_preview_dd";

export const customizableElementsEditorValidations = () => {
  return {
    shouldRenderIfStepSendDocumentTypeIsUsedOnFlow,
    shouldNotRenderErrorsInBusinessAddressWhenKYBTheme,
    shouldRenderSmartDDInput,
    shouldRenderSelectDocs,
    shouldRenderPreviewPDFSwitchWhenSendDocumentTypeStepIsUsedFlow,
    shouldRenderDocumentAutomaticCaptureSettingsJustInBrazilianFlow,
    shouldRenderPreviewDD,
  };
};
