import { useAPIContext } from "@contexts/API";
import Onboarding from "@package/steps";
import isEmpty from "lodash.isempty";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

const { uniqueSteps, duplicableSteps } = Onboarding;

const I18N_BASE_PATH = "src.contexts.onboardingSteps.supportedSteps";

const SupportedSteps = () => {
  const { t, i18n } = useTranslation();
  const { featureFlags } = useAPIContext();

  const [uniqueStepsTranslated, setUniqueStepsTranslated] = useState(uniqueSteps);
  const [duplicableStepsTranslated, setDuplicableStepsTranslated] = useState(duplicableSteps);

  const [allSteps, setAllSteps] = useState({});
  const [supportedUniquePFSteps, setSupportedUniquePFSteps] = useState([]);
  const [supportedPFSteps, setSupportedPFSteps] = useState([]);
  const [supportedUniquePJSteps, setSupportedUniquePJSteps] = useState([]);
  const [supportedPJSteps, setSupportedPJSteps] = useState([]);
  const [supportedFASteps, setSupportedFASteps] = useState([]);
  const [supportedSteps, setSupportedSteps] = useState([]);

  // const clearSaleStep = useMemo(() => {
  //   const tenants = {
  //     dev: ["d7875064-aab8-4471-8e6c-c1fd4e9cc103"],
  //     beta: ["1942f404-e35c-4788-a0de-86943070e85c"],
  //     prod: [
  //       "5b0a4b4d-4a90-4311-aea7-ab6a1d8ea317",
  //       "3b137f66-e1fc-440b-bd75-5bd23cefa313",
  //       "e42866f9-89bd-4aab-abd1-6fa4a84d3662",
  //       "e16fca1f-7a69-4f35-bba6-7fa12cc11420",
  //     ],
  //   };

  //   return tenants[process.env.REACT_APP_ENV].includes(tenantId) ? [allSteps.LIVENESS_CLEARSALE] : [];
  // }, [allSteps]);

  useEffect(() => {
    setUniqueStepsTranslated(uniqueSteps);
    setDuplicableStepsTranslated(duplicableSteps);
  }, [i18n?.language]);

  const translateStepTags = useCallback((stepTags = []) => {
    return (
      stepTags?.map((tag) => {
        return {
          ...tag,
          description: t(`${I18N_BASE_PATH}.tags.${tag?.name}`, tag?.description),
        };
      }) || []
    );
  }, []);

  useEffect(() => {
    setAllSteps({
      WELCOME: {
        ...uniqueStepsTranslated.WELCOME,
        description: t(`${I18N_BASE_PATH}.steps.welcome.description`, "Boas vindas"),
        longDescription: t(`${I18N_BASE_PATH}.steps.welcome.longDescription`, "Dê boas vindas ao usuário"),
        tags: translateStepTags(uniqueStepsTranslated.WELCOME?.tags) || uniqueStepsTranslated.WELCOME?.tags,
      },
      SEND_DOCUMENT_TYPE: {
        ...uniqueStepsTranslated.SEND_DOCUMENT_TYPE,
        description: t(`${I18N_BASE_PATH}.steps.sendDocumentType.description`, "Forma de envio de documento"),
        longDescription: t(
          `${I18N_BASE_PATH}.steps.sendDocumentType.longDescription`,
          "Ofereça formas diferentes de envio de documento para validação",
        ),
        tags:
          translateStepTags(uniqueStepsTranslated.SEND_DOCUMENT_TYPE?.tags) ||
          uniqueStepsTranslated.SEND_DOCUMENT_TYPE?.tags,
      },
      CAMERA_ACCESS: {
        ...uniqueStepsTranslated.CAMERA_ACCESS,
        description: t(`${I18N_BASE_PATH}.steps.cameraAccess.description`, "Permissões de câmera"),
        longDescription: t(`${I18N_BASE_PATH}.steps.cameraAccess.longDescription`, "Peça permissão de acesso a câmera"),
        tags: translateStepTags(uniqueStepsTranslated.CAMERA_ACCESS?.tags) || uniqueStepsTranslated.CAMERA_ACCESS?.tags,
      },
      DATA_CONFIRMATION: {
        ...uniqueStepsTranslated.DATA_CONFIRMATION,
        description: t(`${I18N_BASE_PATH}.steps.dataConfirmation.description`, "Dados pessoais"),
        longDescription: t(
          `${I18N_BASE_PATH}.steps.dataConfirmation.longDescription`,
          "Peça ao usuário alguns dados pessoais",
        ),
        tags:
          translateStepTags(uniqueStepsTranslated.DATA_CONFIRMATION?.tags) ||
          uniqueStepsTranslated.DATA_CONFIRMATION?.tags,
        fields: uniqueStepsTranslated.DATA_CONFIRMATION?.fields?.filter((field) =>
          featureFlags?.isWorkflowBuilder ? !field.hideWhenWorkflowIsActive : true,
        ),
      },
      DD: {
        ...uniqueStepsTranslated.DD,
        description: t(`${I18N_BASE_PATH}.steps.dd.description`, "Captura de Documento"),
        longDescription: t(`${I18N_BASE_PATH}.steps.dd.longDescription`, "Capture o documento do usuário"),
        tags: translateStepTags(uniqueStepsTranslated.DD?.tags) || uniqueStepsTranslated.DD?.tags,
      },
      DISPATCH_ADDRESS: {
        ...uniqueStepsTranslated.DISPATCH_ADDRESS,
        description: t(`${I18N_BASE_PATH}.steps.dispatchAddress.description`, "Endereço residencial"),
        longDescription: t(`${I18N_BASE_PATH}.steps.dispatchAddress.longDescription`, "Peça ao usuário o seu endereço"),
        tags:
          translateStepTags(uniqueStepsTranslated.DISPATCH_ADDRESS?.tags) ||
          uniqueStepsTranslated.DISPATCH_ADDRESS?.tags,
        fields: uniqueStepsTranslated.DISPATCH_ADDRESS?.fields?.filter((field) =>
          featureFlags?.isWorkflowBuilder ? !field.hideWhenWorkflowIsActive : true,
        ),
      },
      BUSINESS_ADDRESS: {
        ...uniqueStepsTranslated.BUSINESS_ADDRESS,
        description: t(`${I18N_BASE_PATH}.steps.businessAddress.description`, "Endereço empresarial"),
        longDescription: t(`${I18N_BASE_PATH}.steps.businessAddress.longDescription`, "Peça pelo endereço"),
        tags:
          translateStepTags(uniqueStepsTranslated.BUSINESS_ADDRESS?.tags) ||
          uniqueStepsTranslated.BUSINESS_ADDRESS?.tags,
      },
      USING_TERMS: {
        ...uniqueStepsTranslated.USING_TERMS,
        description: t(`${I18N_BASE_PATH}.steps.usingTerms.description`, "Aceite de termos de uso"),
        longDescription: t(
          `${I18N_BASE_PATH}.steps.usingTerms.longDescription`,
          "Confirmo estar de acordo com os Termos de Uso e Políticas de Privacidade disponíveis em (link)",
        ),
        tags: translateStepTags(uniqueStepsTranslated.USING_TERMS?.tags) || uniqueStepsTranslated.USING_TERMS?.tags,
      },
      DOCUMENT_ISSUED_COUNTRY: {
        ...uniqueStepsTranslated.DOCUMENT_ISSUED_COUNTRY,
        description: t(`${I18N_BASE_PATH}.steps.documentIssuedCountry.description`, "País de emissão do documento"),
        longDescription: t(
          `${I18N_BASE_PATH}.steps.documentIssuedCountry.longDescription`,
          "Solicite o país de emissão do documento",
        ),
        tags:
          translateStepTags(uniqueStepsTranslated.DOCUMENT_ISSUED_COUNTRY?.tags) ||
          uniqueStepsTranslated.DOCUMENT_ISSUED_COUNTRY?.tags,
      },
      COMPANY_LOCATION: {
        ...uniqueStepsTranslated.COMPANY_LOCATION,
        description: t(`${I18N_BASE_PATH}.steps.companyLocation.description`, "País e Estado da empresa"),
        longDescription: t(
          `${I18N_BASE_PATH}.steps.companyLocation.longDescription`,
          "Solicite o país e o estado da empresa",
        ),
        tags:
          translateStepTags(uniqueStepsTranslated.COMPANY_LOCATION?.tags) ||
          uniqueStepsTranslated.COMPANY_LOCATION?.tags,
        tooltipDescription: t(
          `${I18N_BASE_PATH}.steps.companyLocation.tooltipDescription`,
          "Please note that in some countries (USA, Canada) you may also need to capture a State",
        ),
      },
      COMPANY_SEARCH: {
        ...uniqueStepsTranslated.COMPANY_SEARCH,
        description: t(`${I18N_BASE_PATH}.steps.companySearch.description`, "Nome e número da empresa"),
        longDescription: t(
          `${I18N_BASE_PATH}.steps.companySearch.longDescription`,
          "Solicite o nome e o número da empresa",
        ),
        tags:
          translateStepTags(uniqueStepsTranslated.COMPANY_SEARCH?.tags) || uniqueStepsTranslated.COMPANY_SEARCH?.tags,
      },
      DOCUMENT_TYPE: {
        ...uniqueStepsTranslated.DOCUMENT_TYPE,
        description: t(`${I18N_BASE_PATH}.steps.documentType.description`, "Tipo de documento"),
        longDescription: t(
          `${I18N_BASE_PATH}.steps.documentType.longDescription`,
          "Peça o tipo de documento que o usuário deseja capturar",
        ),
        tags: translateStepTags(uniqueStepsTranslated.DOCUMENT_TYPE?.tags) || uniqueStepsTranslated.DOCUMENT_TYPE?.tags,
      },
      DONE: {
        ...uniqueStepsTranslated.DONE,
        description: t(`${I18N_BASE_PATH}.steps.done.description`, "Fim"),
        longDescription: t(`${I18N_BASE_PATH}.steps.done.longDescription`, "Finalize o onboarding"),
        tags: translateStepTags(uniqueStepsTranslated.DONE?.tags) || uniqueStepsTranslated.DONE?.tags,
      },
      INCOME: {
        ...uniqueStepsTranslated.INCOME,
        description: t(`${I18N_BASE_PATH}.steps.income.description`, "Faturamento"),
        longDescription: t(
          `${I18N_BASE_PATH}.steps.income.longDescription`,
          "Pergunte o faturamento da pessoa ou empresa",
        ),
        tags: translateStepTags(uniqueStepsTranslated.INCOME?.tags) || uniqueStepsTranslated.INCOME?.tags,
      },
      PFL: {
        ...uniqueStepsTranslated.PFL,
        description: t(`${I18N_BASE_PATH}.steps.pfl.description`, "Selfie"),
        longDescription: t(`${I18N_BASE_PATH}.steps.pfl.longDescription`, "Capture uma selfie do usuário"),
        tags: translateStepTags(uniqueStepsTranslated.PFL?.tags) || uniqueStepsTranslated.PFL?.tags,
      },
      LIVENESS_CLEARSALE: {
        ...uniqueStepsTranslated.LIVENESS_CLEARSALE,
        description: t(`${I18N_BASE_PATH}.steps.livenessClearSale.description`, "Selfie ClearSale"),
        longDescription: t(
          `${I18N_BASE_PATH}.steps.livenessClearSale.longDescription`,
          "Capture uma selfie do usuário",
        ),
        tags:
          translateStepTags(uniqueStepsTranslated.LIVENESS_CLEARSALE?.tags) ||
          uniqueStepsTranslated.LIVENESS_CLEARSALE?.tags,
      },
      LIVENESS_FACETEC_3D: {
        ...uniqueStepsTranslated.LIVENESS_FACETEC_3D,
        description: t(`${I18N_BASE_PATH}.steps.livenessFacetec3D.description`, "Selfie FaceTec 3D"),
        longDescription: t(
          `${I18N_BASE_PATH}.steps.livenessFacetec3D.longDescription`,
          "Capture uma selfie do usuário",
        ),
        tags:
          translateStepTags(uniqueStepsTranslated.LIVENESS_FACETEC_3D?.tags) ||
          uniqueStepsTranslated.LIVENESS_FACETEC_3D?.tags,
      },
      LIVENESS_FACETEC_2D: {
        ...uniqueStepsTranslated.LIVENESS_FACETEC_2D,
        description: t(`${I18N_BASE_PATH}.steps.livenessFacetec2D.description`, "Selfie 2D"),
        longDescription: t(
          `${I18N_BASE_PATH}.steps.livenessFacetec2D.longDescription`,
          "Capture uma selfie do usuário",
        ),
        tags:
          translateStepTags(uniqueStepsTranslated.LIVENESS_FACETEC_2D?.tags) ||
          uniqueStepsTranslated.LIVENESS_FACETEC_2D?.tags,
      },
      FACE_AUTH_FACETEC_3D: {
        ...uniqueStepsTranslated.FACE_AUTH_FACETEC_3D,
        description: t(`${I18N_BASE_PATH}.steps.faceAuthFacetec3D.description`, "Autenticação Facial FaceTec 3D"),
        longDescription: t(
          `${I18N_BASE_PATH}.steps.faceAuthFacetec3D.longDescription`,
          "Realiza a autenticação facial do usuário utilizando o SDK da FaceTec 3D",
        ),
        tags:
          translateStepTags(uniqueStepsTranslated.FACE_AUTH_FACETEC_3D?.tags) ||
          uniqueStepsTranslated.FACE_AUTH_FACETEC_3D?.tags,
      },
      FACE_AUTH_FACETEC_2D: {
        ...uniqueStepsTranslated.FACE_AUTH_FACETEC_2D,
        description: t(`${I18N_BASE_PATH}.steps.faceAuthFacetec2D.description`, "Autenticação Facial 2D"),
        longDescription: t(
          `${I18N_BASE_PATH}.steps.faceAuthFacetec2D.longDescription`,
          "Realiza a autenticação facial do usuário utilizando o SDK ",
        ),
        tags:
          translateStepTags(uniqueStepsTranslated.FACE_AUTH_FACETEC_2D?.tags) ||
          uniqueStepsTranslated.FACE_AUTH_FACETEC_2D?.tags,
      },
      LIVENESS_IPROOV: {
        ...uniqueStepsTranslated.LIVENESS_IPROOV,
        description: t(`${I18N_BASE_PATH}.steps.livenessIProov.description`, "Selfie IProov"),
        longDescription: t(`${I18N_BASE_PATH}.steps.livenessIProov.longDescription`, "Capture uma selfie do usuário"),
        tags:
          translateStepTags(uniqueStepsTranslated.LIVENESS_IPROOV?.tags) || uniqueStepsTranslated.LIVENESS_IPROOV?.tags,
      },
      FACE_AUTH_IPROOV: {
        ...uniqueStepsTranslated.FACE_AUTH_IPROOV,
        description: t(`${I18N_BASE_PATH}.steps.faceAuthIProov.description`, "Autenticação Facial iProov"),
        longDescription: t(
          `${I18N_BASE_PATH}.steps.faceAuthIProov.longDescription`,
          "Realiza a autenticação facial do usuário utilizando o SDK da iProov",
        ),
        tags:
          translateStepTags(uniqueStepsTranslated.FACE_AUTH_IPROOV?.tags) ||
          uniqueStepsTranslated.FACE_AUTH_IPROOV?.tags,
      },
      FA: {
        ...uniqueStepsTranslated.FA,
        description: t(`${I18N_BASE_PATH}.steps.fa.description`, "Selfie"),
        longDescription: t(`${I18N_BASE_PATH}.steps.fa.longDescription`, "Capture uma selfie do usuário"),
        tags: translateStepTags(uniqueStepsTranslated.FA?.tags) || uniqueStepsTranslated.FA?.tags,
      },
      CPF_VALIDATION: {
        ...uniqueStepsTranslated.CPF_VALIDATION,
        description: t(`${I18N_BASE_PATH}.steps.cpfValidation.description`, "Validação segura via CPF"),
        longDescription: t(
          `${I18N_BASE_PATH}.steps.cpfValidation.longDescription`,
          "Adicione uma etapa de segurança para o link de onboarding, solicitando que a pessoa informe seu CPF antes de prosseguir.",
        ),
        tags:
          translateStepTags(uniqueStepsTranslated.CPF_VALIDATION?.tags) || uniqueStepsTranslated.CPF_VALIDATION?.tags,
        tooltipDescription: t(
          `${I18N_BASE_PATH}.steps.cpfValidation.tooltipDescription`,
          "Este recurso adiciona uma etapa de validação de CPF durante o processo de onboarding, comparando o valor informado pelo usuário com o informado na geração de um link individual",
        ),
      },
      PHONE_NUMBER_VALIDATION: {
        ...uniqueStepsTranslated.PHONE_NUMBER_VALIDATION,
        description: t(`${I18N_BASE_PATH}.steps.phoneNumberValidation.description`, "Validar telefone"),
        longDescription: t(
          `${I18N_BASE_PATH}.steps.phoneNumberValidation.longDescription`,
          "Valide o telefone do usuário",
        ),
        tags:
          translateStepTags(uniqueStepsTranslated.PHONE_NUMBER_VALIDATION?.tags) ||
          uniqueStepsTranslated.PHONE_NUMBER_VALIDATION?.tags,
      },
      EMAIL_VALIDATION: {
        ...uniqueStepsTranslated.EMAIL_VALIDATION,
        description: t(`${I18N_BASE_PATH}.steps.emailValidation.description`, "Validar email"),
        longDescription: t(`${I18N_BASE_PATH}.steps.emailValidation.longDescription`, "Valide o email do usuário"),
        tags:
          translateStepTags(uniqueStepsTranslated.EMAIL_VALIDATION?.tags) ||
          uniqueStepsTranslated.EMAIL_VALIDATION?.tags,
      },
      UPLOAD_DOCUMENTS: {
        ...uniqueStepsTranslated.UPLOAD_DOCUMENTS,
        description: t(`${I18N_BASE_PATH}.steps.uploadDocuments.description`, "Upload de arquivos"),
        longDescription: t(
          `${I18N_BASE_PATH}.steps.uploadDocuments.longDescription`,
          "Peça alguns documentos ao usuário",
        ),
        tags:
          translateStepTags(uniqueStepsTranslated.UPLOAD_DOCUMENTS?.tags) ||
          uniqueStepsTranslated.UPLOAD_DOCUMENTS?.tags,
      },
      PF_PF_DATA: {
        ...uniqueStepsTranslated.PF_PF_DATA,
        description: t(`${I18N_BASE_PATH}.steps.pf_pf_data.description`, "Dados da pessoa relacionada"),
        longDescription: t(
          `${I18N_BASE_PATH}.steps.pf_pf_data.longDescription`,
          "Peça alguns dados da pessoa relacionada para o fluxo PF PF",
        ),
        tags: translateStepTags(uniqueStepsTranslated.PF_PF_DATA?.tags) || uniqueStepsTranslated.PF_PF_DATA?.tags,
      },
      COMPANY_DATA: {
        ...uniqueStepsTranslated.COMPANY_DATA,
        description: t(`${I18N_BASE_PATH}.steps.companyData.description`, "Dados empresariais"),
        longDescription: t(`${I18N_BASE_PATH}.steps.companyData.longDescription`, "Peça alguns dados da empresa"),
        tags: translateStepTags(uniqueStepsTranslated.COMPANY_DATA?.tags) || uniqueStepsTranslated.COMPANY_DATA?.tags,
      },
      EMPLOYEE_DATA: {
        ...uniqueStepsTranslated.EMPLOYEE_DATA,
        description: t(`${I18N_BASE_PATH}.steps.employeeData.description`, "Dados do funcionário"),
        longDescription: t(
          `${I18N_BASE_PATH}.steps.employeeData.longDescription`,
          "Peça alguns dados do funcionário a realizar o cadastro",
        ),
        tags: translateStepTags(uniqueStepsTranslated.EMPLOYEE_DATA?.tags) || uniqueStepsTranslated.EMPLOYEE_DATA?.tags,
      },
      QSA: {
        ...uniqueStepsTranslated.QSA,
        description: t(`${I18N_BASE_PATH}.steps.qsa.description`, "Quadro societário"),
        longDescription: t(
          `${I18N_BASE_PATH}.steps.qsa.longDescription`,
          "Pergunte qual sócio está realizando o cadastro, e peça o contato dos demais",
        ),
        tags: translateStepTags(uniqueStepsTranslated.QSA?.tags) || uniqueStepsTranslated.QSA?.tags,
      },
      ATTORNEY: {
        ...uniqueStepsTranslated.ATTORNEY,
        description: t(`${I18N_BASE_PATH}.steps.attorney.description`, "Procurador"),
        longDescription: t(
          `${I18N_BASE_PATH}.steps.attorney.longDescription`,
          "Pergunte qual sócio está realizando o cadastro, e peça o contato dos demais",
        ),
        tags: translateStepTags(uniqueStepsTranslated.ATTORNEY?.tags) || uniqueStepsTranslated.ATTORNEY?.tags,
      },
      CUSTOM: {
        ...duplicableStepsTranslated.CUSTOM,
        description: t(`${I18N_BASE_PATH}.steps.custom.description`, "Informativo"),
        longDescription: t(`${I18N_BASE_PATH}.steps.custom.longDescription`, "Realize algum informe ao usuário"),
        tags: translateStepTags(duplicableStepsTranslated.CUSTOM?.tags) || duplicableStepsTranslated.CUSTOM?.tags,
      },
      INSTRUCTIONS: {
        ...duplicableStepsTranslated.INSTRUCTIONS,
        description: t(`${I18N_BASE_PATH}.steps.instructions.description`, "Instruções"),
        longDescription: t(
          `${I18N_BASE_PATH}.steps.instructions.longDescription`,
          "Dê sugestões ou instruções ao usuário",
        ),
        tags:
          translateStepTags(duplicableStepsTranslated.INSTRUCTIONS?.tags) ||
          duplicableStepsTranslated.INSTRUCTIONS?.tags,
      },
      FLOW_CHOICE: {
        ...duplicableStepsTranslated.FLOW_CHOICE,
        description: t(`${I18N_BASE_PATH}.steps.flowChoice.description`, "Decisão"),
        longDescription: t(
          `${I18N_BASE_PATH}.steps.flowChoice.longDescription`,
          "Direcione o usuário baseado em uma decisão",
        ),
        tags:
          translateStepTags(duplicableStepsTranslated.FLOW_CHOICE?.tags) || duplicableStepsTranslated.FLOW_CHOICE?.tags,
      },
      SMART_CHOICE: {
        ...duplicableStepsTranslated.SMART_CHOICE,
        description: t(`${I18N_BASE_PATH}.steps.smartFlowChoice.description`, "Decisão Inteligente"),
        longDescription: t(
          `${I18N_BASE_PATH}.steps.smartFlowChoice.longDescription`,
          "Adapte o fluxo de onboarding com base no tipo de documento selecionado pelo usuário.",
        ),
        tags:
          translateStepTags(duplicableStepsTranslated.SMART_CHOICE?.tags) ||
          duplicableStepsTranslated.SMART_CHOICE?.tags,
      },
      DOCUMENT_REUSE: {
        ...duplicableStepsTranslated.DOCUMENT_REUSE,
        description: t(`${I18N_BASE_PATH}.steps.documentReuse.description`, "Reutilização de documentos"),
        longDescription: t(
          `${I18N_BASE_PATH}.steps.documentReuse.longDescription`,
          "Capture o documento de identidade do solicitante",
        ),
        tags:
          translateStepTags(duplicableStepsTranslated.DOCUMENT_REUSE?.tags) ||
          duplicableStepsTranslated.DOCUMENT_REUSE?.tags,
        tooltipDescription: t(
          `${I18N_BASE_PATH}.documentReuse.tooltipDescription`,
          "Este recurso não gera uma nova tela no seu fluxo, apenas cria uma regra.",
        ),
      },
      CUSTOM_FORM: {
        ...duplicableStepsTranslated.CUSTOM_FORM,
        description: t(`${I18N_BASE_PATH}.steps.customForm.description`, "Formulário"),
        longDescription: t(
          `${I18N_BASE_PATH}.steps.customForm.longDescription`,
          "Peça alguns dados adicionais ao usuário",
        ),
        tags:
          translateStepTags(duplicableStepsTranslated.CUSTOM_FORM?.tags) || duplicableStepsTranslated.CUSTOM_FORM?.tags,
      },
      PHONE_NUMBER_VALIDATION: {
        ...uniqueStepsTranslated.PHONE_NUMBER_VALIDATION,
        description: t(`${I18N_BASE_PATH}.steps.phoneNumberValidation.description`),
        longDescription: t(`${I18N_BASE_PATH}.steps.phoneNumberValidation.description`),
        customizables: uniqueStepsTranslated.PHONE_NUMBER_VALIDATION.customizables.filter((value) => {
          if (value === "STEP_PHONE-NUMBER-VALIDATION-METHOD") {
            return featureFlags?.phoneNumberValidationMode;
          }
          return true;
        }),
      },
    });
  }, [duplicableStepsTranslated, uniqueStepsTranslated, i18n.language, featureFlags]);

  /** Unique PF steps that are currently supported by Builder */
  useEffect(() => {
    if (!isEmpty(allSteps)) {
      setSupportedUniquePFSteps([
        allSteps.WELCOME,
        allSteps.SEND_DOCUMENT_TYPE,
        allSteps.CAMERA_ACCESS,
        allSteps.DATA_CONFIRMATION,
        allSteps.DD,
        allSteps.DISPATCH_ADDRESS,
        allSteps.DOCUMENT_ISSUED_COUNTRY,
        allSteps.USING_TERMS,
        allSteps.DOCUMENT_TYPE,
        allSteps.DONE,
        allSteps.INCOME,
        allSteps.PFL,
        allSteps.FA,
        allSteps.PHONE_NUMBER_VALIDATION,
        allSteps.EMAIL_VALIDATION,
        allSteps.UPLOAD_DOCUMENTS,
        allSteps.PF_PF_DATA,
        allSteps.CPF_VALIDATION,
        allSteps.DOCUMENT_REUSE,
      ]);
    }
  }, [allSteps]);

  function getFacetecBuilderSteps(type) {
    if (type === "faceAuth") {
      if (featureFlags?.facetecFaceAuthBuilderSteps?.state !== true) {
        return [];
      }

      let steps = [];
      if (featureFlags?.facetecFaceAuthBuilderSteps?.fields?.includes("3D")) {
        steps.push(allSteps.FACE_AUTH_FACETEC_3D);
      }
      if (featureFlags?.facetecFaceAuthBuilderSteps?.fields?.includes("2D")) {
        steps.push(allSteps.FACE_AUTH_FACETEC_2D);
      }

      return steps;
    }

    if (type === "liveness") {
      if (featureFlags?.facetecLivenessBuilderSteps?.state !== true) {
        return [];
      }

      let steps = [];
      if (featureFlags?.facetecLivenessBuilderSteps?.fields?.includes("3D")) {
        steps.push(allSteps.LIVENESS_FACETEC_3D);
      }
      if (featureFlags?.facetecLivenessBuilderSteps?.fields?.includes("2D")) {
        steps.push(allSteps.LIVENESS_FACETEC_2D);
      }

      return steps;
    }

    return [];
  }

  /** PF steps that are currently supported by Builder */
  useEffect(() => {
    if (!isEmpty(allSteps)) {
      setSupportedPFSteps([
        allSteps.WELCOME,
        allSteps.SEND_DOCUMENT_TYPE,
        allSteps.CAMERA_ACCESS,
        allSteps.DATA_CONFIRMATION,
        allSteps.DD,
        allSteps.DISPATCH_ADDRESS,
        allSteps.DOCUMENT_ISSUED_COUNTRY,
        allSteps.USING_TERMS,
        allSteps.DOCUMENT_TYPE,
        allSteps.DONE,
        allSteps.INCOME,
        allSteps.PFL,
        ...(featureFlags?.clearSalePFL ? [allSteps.LIVENESS_CLEARSALE] : []),
        ...getFacetecBuilderSteps("liveness"),
        ...(featureFlags?.iProovPFL ? [allSteps.LIVENESS_IPROOV] : []),
        allSteps.PHONE_NUMBER_VALIDATION,
        allSteps.EMAIL_VALIDATION,
        allSteps.UPLOAD_DOCUMENTS,
        allSteps.PF_PF_DATA,
        allSteps.CUSTOM,
        allSteps.INSTRUCTIONS,
        allSteps.FLOW_CHOICE,
        allSteps.SMART_CHOICE,
        allSteps.CUSTOM_FORM,
        allSteps.CPF_VALIDATION,
        allSteps.DOCUMENT_REUSE,
      ]);
    }
  }, [allSteps]);

  /** Unique PJ steps that are currently supported by Builder */
  useEffect(() => {
    if (!isEmpty(allSteps)) {
      setSupportedUniquePJSteps([
        allSteps.COMPANY_DATA,
        allSteps.EMPLOYEE_DATA,
        allSteps.QSA,
        allSteps.ATTORNEY,
        allSteps.DOCUMENT_ISSUED_COUNTRY,
        allSteps.COMPANY_LOCATION,
        allSteps.COMPANY_SEARCH,
        allSteps.DOCUMENT_REUSE,
      ]);
    }
  }, [allSteps]);

  /** PJ steps that are currently supported by Builder */
  /** Unique PJ steps that are currently supported by Builder */
  useEffect(() => {
    if (!isEmpty(allSteps)) {
      setSupportedPJSteps([
        allSteps.COMPANY_DATA,
        allSteps.EMPLOYEE_DATA,
        allSteps.QSA,
        allSteps.ATTORNEY,
        allSteps.WELCOME,
        allSteps.SEND_DOCUMENT_TYPE,
        allSteps.CAMERA_ACCESS,
        allSteps.DATA_CONFIRMATION,
        allSteps.DD,
        allSteps.DISPATCH_ADDRESS,
        allSteps.DOCUMENT_ISSUED_COUNTRY,
        allSteps.COMPANY_LOCATION,
        allSteps.COMPANY_SEARCH,
        allSteps.BUSINESS_ADDRESS,
        allSteps.DOCUMENT_TYPE,
        allSteps.USING_TERMS,
        allSteps.DONE,
        allSteps.INCOME,
        allSteps.PFL,
        ...(featureFlags?.clearSalePFL ? [allSteps.LIVENESS_CLEARSALE] : []),
        ...getFacetecBuilderSteps("liveness"),
        ...(featureFlags?.iProovPFL ? [allSteps.LIVENESS_IPROOV] : []),
        allSteps.PHONE_NUMBER_VALIDATION,
        allSteps.EMAIL_VALIDATION,
        allSteps.UPLOAD_DOCUMENTS,
        allSteps.CUSTOM,
        allSteps.INSTRUCTIONS,
        allSteps.FLOW_CHOICE,
        allSteps.SMART_CHOICE,
        allSteps.CUSTOM_FORM,
        allSteps.DOCUMENT_REUSE,
      ]);
    }
  }, [allSteps]);

  /** FA steps that are currently supported by Builder */
  useEffect(() => {
    if (!isEmpty(allSteps)) {
      setSupportedFASteps([
        allSteps.WELCOME,
        allSteps.DATA_CONFIRMATION,
        allSteps.CAMERA_ACCESS,
        allSteps.EMAIL_VALIDATION,
        allSteps.PHONE_NUMBER_VALIDATION,
        allSteps.UPLOAD_DOCUMENTS,
        allSteps.INSTRUCTIONS,
        allSteps.CUSTOM,
        allSteps.CUSTOM_FORM,
        allSteps.FA,
        ...getFacetecBuilderSteps("faceAuth"),
        ...(featureFlags?.iProovPFL ? [allSteps.FACE_AUTH_IPROOV] : []),
        allSteps.DONE,
        allSteps.CPF_VALIDATION,
      ]);
    }
  }, [allSteps]);

  /** All steps that are currently supported by Builder */
  useEffect(() => {
    if (!isEmpty(allSteps)) {
      setSupportedSteps([...supportedPJSteps, allSteps.PF_PF_DATA]);
    }
  }, [allSteps, supportedPJSteps]);

  return {
    supportedSteps,
    supportedPJSteps,
    supportedPFSteps,
    supportedUniquePFSteps,
    supportedUniquePJSteps,
    supportedFASteps,
  };
};

export default SupportedSteps;
