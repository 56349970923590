import Onboarding from "@package/steps";
import { parseStep } from "@utils/onboarding";
import {
  formatListContainsOnlyOneItem,
  templateStepsOrderValidationsRules,
  validateTemplateStepsOrderBasedOnEachFlow,
} from "@utils/template-validations/steps-validations/utils";
import {
  templateValidationsMessages,
  validateTemplateMessages,
} from "@utils/template-validations/templateValidationsMessages";
import isEqual from "lodash.isequal";

const { steps } = Onboarding;

// The "DOCUMENT_TYPE" step must have at least one document type
export const documentTypeStepMustHasAtLeastOneDocumentType = (
  onboardingOptions = {},
  templateValidationNotificationsToAdd = () => [],
) => {
  // TODO: refactor validation logic after refactor terms and documentTypes from template
  const templateHasNotOneDocumentType =
    onboardingOptions?.steps?.some((_step) => isEqual(parseStep(_step?.name)[0], steps.DOCUMENT_TYPE.name)) &&
    onboardingOptions?.documentTypes?.length === 0;

  // The "DOCUMENT_TYPE" step must have at least one document type
  if (templateHasNotOneDocumentType) {
    templateValidationNotificationsToAdd.push(
      validateTemplateMessages(templateValidationsMessages.TEMPLATE_HAS_NOT_ONE_DOCUMENT_TYPE),
    );
  }
};

// If the "DOCUMENT_TYPE" step has the ANY document type option selected, show warning
export const documentTypeStepHasDocumentTypeAny = (
  onboardingOptions = {},
  templateValidationNotificationsToAdd = () => [],
) => {
  // TODO: refactor validation logic after refactor terms and documentTypes from template
  const templateHasAnyDocumentType =
    onboardingOptions?.steps?.some((_step) => isEqual(parseStep(_step?.name)[0], steps.DOCUMENT_TYPE.name)) &&
    onboardingOptions?.documentTypes?.length > 0 &&
    onboardingOptions?.documentTypes?.findIndex((dt) => isEqual(dt.type, "ANY")) !== -1;

  if (templateHasAnyDocumentType) {
    templateValidationNotificationsToAdd.push(
      validateTemplateMessages(templateValidationsMessages.DOCUMENT_TYPE_HAS_ANY_OPTION),
    );
  }
};

// If the template has at least one use terms, verify if at least one is required
export const documentTypeStepMustHaveAtLeastOneRequiredUseTerms = (
  onboardingSteps = [],
  templateValidationNotificationsToAdd = () => [],
) => {
  // Marketplace theme has a terms of use step
  const termsOfUseStep = onboardingSteps.filter((_step) => isEqual(parseStep(_step?.name)[0], steps.TERMS_OF_USE.name));
  if (termsOfUseStep.length > 0) {
    return;
  }

  // TODO: refactor validation logic after refactor terms and documentTypes from template
  const documentTypeSteps = onboardingSteps.filter((_step) =>
    isEqual(parseStep(_step?.name)[0], steps.DOCUMENT_TYPE.name),
  );
  const termsByStep = documentTypeSteps.map((step) => step?.terms ?? []);

  const templateHasAtLeastOneUseTermsRequired = termsByStep?.every((terms) => terms?.some((term) => term?.required));

  if (!templateHasAtLeastOneUseTermsRequired) {
    templateValidationNotificationsToAdd.push(
      validateTemplateMessages(templateValidationsMessages.TEMPLATE_HAS_NOT_ONE_USE_TERMS_REQUIRED),
    );
  }
};

// The "DOCUMENT_TYPE" step must be before "DD" step
export const documentTypeStepMustBeBeforeDDStep = (
  documentTypeFlowsStepsIndexes = [],
  DDFlowsStepsIndexes = [],
  templateValidationNotificationsToAdd = () => [],
) => {
  const documentTypeIsInFrontOfDDValidations = validateTemplateStepsOrderBasedOnEachFlow(
    [documentTypeFlowsStepsIndexes, DDFlowsStepsIndexes],
    templateStepsOrderValidationsRules.BETWEEN_TWO_STEPS,
  );

  if (!documentTypeIsInFrontOfDDValidations?.length) {
    return;
  }

  let documentTypeIsInFrontOfDDFlows = documentTypeIsInFrontOfDDValidations?.map((validation) => validation?.flow);

  // add a new template validation if the document type step is in front of DD step based of each flow
  if (!!documentTypeIsInFrontOfDDFlows?.length && documentTypeIsInFrontOfDDFlows?.every((flowName) => !!flowName)) {
    documentTypeIsInFrontOfDDFlows = formatListContainsOnlyOneItem(documentTypeIsInFrontOfDDFlows);

    templateValidationNotificationsToAdd.push(
      validateTemplateMessages(templateValidationsMessages.DOCUMENT_TYPE_IS_IN_FRONT_OF_DD)(
        documentTypeIsInFrontOfDDFlows,
      ),
    );
  }
};

export const templateShouldHaveDocumentTypeOrDocumentIssuedCountryIfDDIsEnabled = (
  DDFlowsStepsIndexes = [],
  documentTypeFlowsStepsIndexes = [],
  documentIssuedCountryFlowsStepsIndexes = [],
  onboardingSteps = [],
  templateValidationNotificationsToAdd = () => [],
) => {
  const templateHasDD = !!DDFlowsStepsIndexes?.length;
  const templateHasDocumentType = !!documentTypeFlowsStepsIndexes?.length;
  const templateHasDocumentIssuedCountry = !!documentIssuedCountryFlowsStepsIndexes?.length;
  const documentDetectStep = onboardingSteps.filter((_step) => isEqual(parseStep(_step?.name)[0], steps.DD.name));

  const templateHasDDFlag = documentDetectStep?.[0]?.autoDocumentSdk;

  if (!templateHasDD) {
    return;
  }

  if (!templateHasDocumentType && !templateHasDocumentIssuedCountry && !templateHasDDFlag) {
    templateValidationNotificationsToAdd.push(
      validateTemplateMessages(
        templateValidationsMessages.TEMPLATE_MUST_HAVE_DOCUMENT_TYPE_OR_DOCUMENT_ISSUED_COUNTRY,
      )(),
    );
  }
};

export const templateShouldHaveDocumentTypeOrAutoDocumentSdkDD = (
  DDFlowsStepsIndexes = [],
  documentTypeFlowsStepsIndexes = [],
  onboardingSteps = [],
  templateValidationNotificationsToAdd = () => [],
) => {
  const templateHasDD = !!DDFlowsStepsIndexes?.length;
  const templateHasDocumentType = !!documentTypeFlowsStepsIndexes?.length;
  const documentDetectStep = onboardingSteps.filter((_step) => isEqual(parseStep(_step?.name)[0], steps.DD.name));

  const templateHasDDFlag = documentDetectStep?.[0]?.autoDocumentSdk;

  if (!templateHasDocumentType) {
    return;
  }

  if (templateHasDDFlag && templateHasDocumentType) {
    templateValidationNotificationsToAdd.push(
      validateTemplateMessages(
        templateValidationsMessages.TEMPLATE_MUST_HAVE_DOCUMENT_TYPE_OR_DD_SDK_AUTODETECT_FLAG,
      )(),
    );
  }
};

export const templateShouldHaveDocumentsType = (
  onboardingSteps = [],
  templateValidationNotificationsToAdd = () => [],
) => {
  const documentDetectStep = onboardingSteps.filter((_step) => isEqual(parseStep(_step?.name)[0], steps.DD.name));

  const templateHasDDFlag = documentDetectStep?.[0]?.autoDocumentSdk;
  const templateHasAnyDocumentFlag = documentDetectStep?.[0]?.acceptAnyDocument;
  const templateHasDocs = documentDetectStep?.[0]?.expectedDocumentsType;

  if (!templateHasDDFlag || templateHasAnyDocumentFlag) {
    return;
  }

  if (!templateHasDocs?.length) {
    templateValidationNotificationsToAdd.push(
      validateTemplateMessages(templateValidationsMessages.TEMPLATE_MUST_HAVE_EXPECTED_DOCUMENT_TYPES)(),
    );
  }
};
