import Onboarding from "@package/steps";
import { parseStep } from "@utils/onboarding";
import {
  templateValidationsMessages,
  validateTemplateMessages,
} from "@utils/template-validations/templateValidationsMessages";
import isEqual from "lodash.isequal";

const { steps } = Onboarding;

export const usingTermsStepMustHaveAtLeastOneRequiredUseTerms = (
  onboardingSteps = [],
  templateValidationNotificationsToAdd = () => [],
) => {
  const documentTypeSteps = onboardingSteps.filter((_step) =>
    isEqual(parseStep(_step?.name)[0], steps.USING_TERMS.name),
  );
  const termsByStep = documentTypeSteps.map((step) => step?.terms ?? []);

  const templateHasAtLeastOneUseTermsRequired = termsByStep?.every((terms) => terms?.some((term) => term?.required));

  if (!templateHasAtLeastOneUseTermsRequired) {
    templateValidationNotificationsToAdd.push(
      validateTemplateMessages(templateValidationsMessages.TEMPLATE_HAS_NOT_ONE_USE_TERMS_REQUIRED_USING_TERMS),
    );
  }
};

export const usingTermsStepMustHaveDocumentReuseIfRequired = (
  onboardingSteps = [],
  templateValidationNotificationsToAdd = () => [],
) => {
  // Check if the flow reuse has the "DD" step.
  const ddStep = onboardingSteps.filter(
    (_step) => isEqual(parseStep(_step?.name)[0], steps.DD.name) && _step?.flow?.includes("REUSE"),
  );

  if (ddStep?.length) {
    templateValidationNotificationsToAdd.push(
      validateTemplateMessages(templateValidationsMessages.FLOW_REUSE_HAS_DD_STEP),
    );
  }
};
