import { customApi } from "@services/api";
import api from "@services/api";
import Cookies from "js-cookie";

import { authenticationUtils } from "./utils";

const { AUTHENTICATION_COOKIES_OPTIONS } = authenticationUtils;

export const getLoggedUser = async ({ url = "", token = "", history = {} }) => {
  return await customApi
    .get(url, {
      headers: {
        Authorization: token,
      },
    })
    .then(({ data: response }) => {
      const { data } = response;
      return data || {};
    })
    .catch((error) => {
      const {
        response: {
          data: { statusCode },
        },
      } = error || {};

      if (statusCode === 401) {
        history.push("/auth/login");
        return;
      }
    });
};

export const getClientFlags = async ({ url = "", token = "", history = {} }) => {
  return await customApi
    .get(url, {
      headers: {
        Authorization: token,
      },
    })
    .then((response) => {
      const { data } = response;
      return data?.docs || {};
    })
    .catch((error) => {
      const {
        response: {
          data: { statusCode },
        },
      } = error || {};

      if (statusCode === 401) {
        history.push("/feature-flags");
        return;
      }
    });
};

// If the user is logged in and he/she is a client, he only can access his/her only templates (onboarding and emails)
export const validateCafClientAccess = ({ authToken = "", tenantId = "", history = {} }) => {
  getLoggedUser({
    url: `${process.env.REACT_APP_BASE_URL_AUTH_API}users/me`,
    token: `Bearer ${authToken}`,
    history,
  }).then((user) => {
    const { tenantId: _tenantId } = user || {};

    if (tenantId !== _tenantId) {
      history.push("/auth/unauthorized");
    }
  });
};

// authenticate CAF user by Bifrost and redirect him/her to the customers list at Backoffice
export const authenticateCafOperator = ({ code = "", history = {} }) => {
  const redirectUri = encodeURIComponent(
    AUTHENTICATION_COOKIES_OPTIONS.isLocalhost
      ? `${window.location.origin}/auth/login`
      : `${process.env.REACT_APP_URL}auth/login`,
  );

  api
    .post(`${process.env.REACT_APP_BASE_URL_BIFROST_API}users/signin/${code}?redirect_uri=${redirectUri}`)
    .then(({ data }) => {
      const { idToken: bifrostToken, refreshToken: bifrostRefreshToken } = data || {};

      if (!!bifrostToken && !!bifrostRefreshToken) {
        Cookies.set(
          AUTHENTICATION_COOKIES_OPTIONS.BIFROST_ID_TOKEN,
          bifrostToken,
          AUTHENTICATION_COOKIES_OPTIONS.COOKIE_OPTIONS,
        );
        Cookies.set(
          AUTHENTICATION_COOKIES_OPTIONS.BIFROST_REFRESH_TOKEN,
          bifrostRefreshToken,
          AUTHENTICATION_COOKIES_OPTIONS.COOKIE_OPTIONS,
        );

        window.location = `${process.env.REACT_APP_BASE_URL_BACKOFFICE}customers`;
        return;
      }

      history.push("/auth/login");
      return;
    })
    .catch((error) => {
      history.push("/auth/login");
      return;
    });
};

// If the user came from a redirect made by Auth, redirect him/her to onboarding template list based on logged user tenant id
export const redirectCafClientAfterAuthRedirect = ({ authToken = "", authUrl = "", history = {} }) => {
  getLoggedUser({ url: authUrl, token: `Bearer ${authToken}`, history }).then((user) => {
    const { tenantId } = user || {};

    if (!!tenantId) {
      history.push(`/onboarding?tenantId=${tenantId}`);
    } else {
      history.push("/onboarding");
    }
  });

  return;
};
